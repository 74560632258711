import { Link } from "react-router-dom";
import * as Icon from "react-feather";
const  Nav = () => {
    return (
      <div className="header">  
      <div className="header__logo">
        <strong>FOX PRODUCCIONES</strong>
      </div>
      <nav className="navbar">
        <ul className="navbar__menu">
          <li className="navbar__item">
            <Link to="/" className="navbar__link"><Icon.Home/><span>Home</span></Link>
          </li>
          <li className="navbar__item">
            <Link to="/galeria" className="navbar__link"><Icon.Film/><span>Portafolio</span></Link>        
          </li>
          <li className="navbar__item">
            <Link to="/precios" className="navbar__link"><Icon.DollarSign/><span>Precios</span></Link>        
          </li>
          <li className="navbar__item">
            <Link to="/contacto" className="navbar__link"><Icon.MessageCircle/><span>Contacto</span></Link>        
          </li>
         
        </ul>
   
      </nav>
    </div>
    

  
    )
}
export default Nav;