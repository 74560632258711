import { Link } from "react-router-dom";
const   Fallapage = () => {
    return (
        <div>
        <h1>404 Error Page </h1>
        <p className="zoom-area"><b>Esta pagina</b> no existe </p>
        <section className="error-container">
          <span>4</span>
          <span><span className="screen-reader-text">0</span></span>
          <span>4</span>
        </section>
        <div className="link-container">
          <Link  to="/" className="more-link">Visit the original article</Link>
        </div>
        </div>
    )
}
export default Fallapage;