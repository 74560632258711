const Inicio = () => {
    return (
<div className="conteprin">       
    <div className="scene">
    <div className="videoback">

    <video autoPlay muted loop id="video">
        <source src='http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4' type="video/mp4" />
    </video>

    </div>
    <div className="camera">
        <div className="battery">
        <div className="juice"></div>
        </div>
        <div className="rec">
        <span><span></span>REC</span>
        </div>
        <div className="meta">
        <p>F2.3&nbsp;&nbsp;0dB&nbsp;&nbsp;15.7V 
            <span className="exposure">
            <span className="plus">+</span>
            <span className="minus">-</span>
            </span>
        </p>
        </div>
        <div className="timer">
        <label id="hours">00</label>:
        <label id="minutes">00</label>:
        <label id="seconds">00</label>
        </div>
    </div>
    </div>
</div>
    )
}
export default Inicio;