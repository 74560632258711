import "bootstrap/dist/css/bootstrap.min.css";
import "./css/foxproducciones.css"

import "./js/main.js"


import Inicio from "./pages/inicio";
import Nav from "./pages/nav";
import Footer from "./pages/footer";
import Contac from "./pages/contac";
import Fallapage from "./pages/fallapage";
import { BrowserRouter, Routes, Route} from "react-router-dom"
import Galeria from "./pages/galeria";

function App() {
  return (
    <div className="Container">
    <BrowserRouter>
      <Nav />
        <Routes>
          <Route path="/" element={<Inicio />}/>
          <Route path="/contac" element={<Contac />}/>
          <Route path="/galeria" element={<Galeria />}/>
          <Route path="/*" element={<Fallapage />}/>
        </Routes>
      <Footer />
    </BrowserRouter>
      
    </div>
  );
}

export default App;
